import { render, staticRenderFns } from "./ButtonBackStart.vue?vue&type=template&id=43ce2306&scoped=true&"
import script from "./ButtonBackStart.vue?vue&type=script&lang=js&"
export * from "./ButtonBackStart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43ce2306",
  null
  
)

export default component.exports